/* Theme: Geeks */
@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&display=swap');

body{
  font-family: 'Lato', san-serif !important;
  overflow-x: hidden;
}
.bg-is-transparent{
  background-color: rgba(230, 230, 230, 0.75);
}
.bg-dark{
  background-color: #0D0D0D !important;
}
.text-gradient-mix-shade {
  background: -webkit-linear-gradient(#4A52FF, #C678FF, #FE5D75);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-background-position: left;
}

.list-group{
    max-height: 100vh;
    margin-bottom: 10px;
    overflow:scroll;
    -webkit-overflow-scrolling: touch;
}

.clickable{
  cursor: pointer;
}

.gradient-color{
  background-color: -webkit-linear-gradient(#4A52FF, #C678FF, #FE5D75);
  color:#fff
}
.twitter-color{
  background-color: #1DA1F2
}
.twitter-color:hover{
  background-color: #1DA1F2
}
.twitter-color:focus{
  background-color: #1DA1F2
}
.opensea-color{
  background-color: #1868B7
}
.opensea-color:hover{
  background-color: #1868B7
}
.opensea-color:focus{
  background-color: #1868B7
}
.metamask-color{
  background-color: #D7C1B3
}
.metamask-color:hover{
  background-color: #D7C1B3
}
.metamask-color:focus{
  background-color: #D7C1B3
}
.rounded-top-3{
  border-top-left-radius: 0.5rem !important;
  border-top-right-radius: 0.5rem !important;
}
.rounded-bottom-3{
  border-bottom-left-radius: 0.5rem !important;
  border-bottom-right-radius: 0.5rem !important;
}
.offcanvas-bottom-custom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%); }
